<template>
    <front-layout>
        <div class="Faq intro">
            <div class="Faq_search Faq_search-dark py-10">
                <div class="container">
                    <h1 class="text-center mb-10" v-text="'How can we help?'"/>
                    <v-text-field @keyup.enter="search" v-model="searchQuery" label="Search" filled  dark prepend-inner-icon="mdi-magnify"/>
                </div>
            </div>
            <div class="container mt-10">
                <v-row>
                    <v-col v-for="(article, idx) in faq" md="4" :key="idx">
                        <v-card @click.prevent="toArticle(article)" outlined elevation="0"
                                class="Faq_item v-card--hover">
                            <v-card-title class="justify-center" v-text="article.title"/>
                            <v-card-text
                                class="text-center"
                                v-text="article.text.replace(/(<([^>]+)>)/gi, '').substr(0, 400) + '...'"/>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Faq from "../../models/Faq";

    export default {
        name: "Faq",
        components: {FrontLayout},
        data() {
            return {
                faq: [],
                searchQuery: null
            }
        },
        async mounted() {
            this.faq = await this.getFaq()
        },
        methods: {
            getFaq() {
                return Faq.where('root', true).get()
            },
            toArticle(article) {
                this.$router.push({name: 'faq.show', params: {id: article.id}})
            },
            search() {
                if (this.searchQuery !== null || this.searchQuery !== '') {
                    this.$router.push({name: 'faq.search', params: {query: this.searchQuery}})
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.Faq {
    &_search {
        &-dark {
            color: white;
            background: var(--v-secondary-darken1);

            a {
                color: var(--v-secondary-lighten5);
            }
        }
    }

    .v-card--hovered {
        &:hover {
        }
    }

    &_item {
        display: flex;
        min-height: 300px;
        justify-content: center;
        flex-direction: column;
    }
}
</style>
